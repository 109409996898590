import { mkNode, removeNode } from '@p4b/utils';
import { LocalData } from '@p4b/exam-service'
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, Layout, QuestionArgs } from '@p4b/question-base';
import { Lightbox } from '@p4b/lightbox';
import { Dropdown } from '@p4b/component-dropdown';
import { translate } from '@p4b/utils-lang';
import { configSafePress } from '@p4b/exam-accessibility';

type Option = {
    value: string;
    backendId?: string;
} | null;

function isOption(opt: unknown): opt is {value: string, backendId?: string} {
    if (typeof opt === 'object' && opt != null) {
        const obj = opt as {[opt:string]:unknown};
        return typeof obj.value === 'string' && (
            typeof obj.backendId === 'undefined' ||
            typeof obj.backendId === 'string'
        );
    }
    return false;
}

/** Dropdown question textarea UI */
export class QuestionDropdown extends QuestionBase implements Question {
    private answerItem: HTMLDivElement;
    private dropdown: Dropdown;

    private answer: Option;
    private updateVisibility: () => void;
    private values: ({value: string, backendId: string}|null)[] = [];

    public readonly visibilityExpression?: Expr;

    /** Construct Dropdown Question UI */
    public constructor(args: Omit<QuestionArgs, 'showFlag'> & {
        updateVisibility: () => void,
        visibilityExpression?: Expr,
        options: string[],
        optionDetails: PractiqueNet.ExamJson.Definitions.AnswerOptionDetails[],
        optionOrder?: number[],
    }) {
        super({...args, showFlag: true, layout: args.showNumber ? Layout.CompactFloat : Layout.Default});
        const {indent, options, optionOrder, optionDetails, updateVisibility, visibilityExpression} = args;
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`;
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        this.dropdown = new Dropdown({
            name: 'answer',
            parent: this.answerItem,
            disabled: true,
            selectedClass: configSafePress,
            optionClass: configSafePress,
        });
        this.dropdown.addOption(translate('NOT_ANSWERED'), '', '-1');
        this.values.push(null);
        for (let i = 0; i < options.length; ++i) {
            const idx = (optionOrder) ? optionOrder[i] : i;
            const value = options[idx];
            const details = optionDetails[idx];
            this.dropdown.addOption(value, details.description, String(details.backend_id));
            if (value && details.backend_id) {
                this.values.push({value, backendId: String(details.backend_id)});
            } else {
                this.values.push(null);
            }
        }
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        this.answer = null;
    }

    public updateDisable(): void {
        super.updateDisable();
        this.dropdown.disable(this.isDisabled());
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData): void {
        try {
            if (response && isOption(response.answer)) {
                this.dropdown.selectByName(response.answer.value);
            } else {
                this.dropdown.selectByIndex(0);
            }
            this.answer = this.values[this.dropdown.selectedIndex()];
            this.updateVisibility();
        } catch(e) {
            console.error(String(e));
        }
    }

    public loadingComplete(): void {
        super.loadingComplete();
        this.answerItem.addEventListener('dropdown-select', this.handleSelect);
    }

    /** Get the answer value */
    public getValue(): string {
        return this.answer?.value ?? '';
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        removeNode(this.answerItem);
        this.answerItem.removeEventListener('dropdown-select', this.handleSelect);
        super.destroy();
    }

    public focus(): void {
        this.dropdown.focus();
    }

    //public getAnswer(): AnswerKey & AnswerValue {
    //    return {qno: this.qno, ano: this.ano,  answer: this.answer};
    //}

    private readonly handleSelect = async (event: Event) => {
        console.debug('HANDLE_SELECT', event);
        if (event instanceof CustomEvent && typeof event.detail === 'number') {
            try {
                this.answer = this.values[event.detail];
                await this.context.responses.saveAnswer({qno: this.qno, ano: this.ano}, {answer: this.answer});
            } catch(err) {
                console.error(String(err));
            }
            this.updateVisibility();
        }
    }
}

registerAnswerType({
    name: 'Dropdown',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer, n?: number, meta?: PractiqueNet.ExamJson.Definitions.ExamMeta, isOSCE?: boolean): boolean => {
        return (answer.type.toLowerCase() === 'sba' && ((n !== undefined && n > 1) && (isOSCE === true || (meta?.disableAnswerElimination ?? true)))) || answer.type.toLowerCase() === 'dropdown';
    },
    makeAnswer: (
        qno: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.Answer,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
        isOSCE: boolean,
    ) => new QuestionDropdown({
        updateVisibility,
        context,
        qno,
        ano,
        backendQid: question.manifest.backend_id,
        backendAid: answer.backend_id,
        showNumber: question.manifest.answers.length > 1,
        label: answer.label,
        frag,
        options: (answer.type === 'SBA' || answer.type === 'dropdown') ? answer.options : [],
        optionDetails: (answer.type === 'SBA' || answer.type === 'dropdown') ? (answer.optionDetails ?? []) : [],
        lightbox,
        isRemoteShowHide,
        indent: answer.indent,
        visibilityExpression: answer.visible,
        optionOrder: (answer.type === 'SBA' || answer.type === 'dropdown') ? answer.candidateToOptionOrder?.[context.candidateId] : [],
        notes: answer.notes,
        resources: question.answersResources[ano],
        mandatory: answer.mandatory,
        type: answer.type.toLowerCase(),
        isOSCE,
    })
});
